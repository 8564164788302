import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { ref } from 'vue';
export default {
  name: 'App',

  setup() {
    const locale = ref(zhCn);
    return {
      locale
    };
  }

};