import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst';
export default {
  name: 'MyLogin',

  setup() {
    const router = useRouter();
    const user = reactive({
      password: '',
      username: ''
    });

    const handleClickLogin = () => {
      axios.post(axiosUrl + "admin/login", {
        username: user.username,
        password: user.password
      }).then(response => {
        if (response.data.success) {
          router.replace({
            name: 'LongJob'
          });
          sessionStorage.setItem("username", response.data.data.username);
          sessionStorage.setItem("token", response.data.data.token);
          ElMessage.success("登录成功");
        } else {
          ElMessage.error("登录名或密码错误");
        }
      }).catch(() => {
        ElMessage.error("登录名或密码错误");
      });
    };

    return {
      user,
      handleClickLogin
    };
  }

};