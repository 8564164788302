import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_my_aside = _resolveComponent("my-aside");

  const _component_my_header = _resolveComponent("my-header");

  const _component_router_view = _resolveComponent("router-view");

  const _component_el_main = _resolveComponent("el-main");

  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_component_el_container, {
    style: {
      "height": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_my_aside), _createVNode(_component_el_container, {
      style: {
        "flex-direction": "column"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_my_header), _createVNode(_component_el_main, {
        style: {
          "background-color": "#f6f8f9"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}