import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { useRouter } from 'vue-router';
export default {
  name: 'MyHeader',

  setup() {
    const username = sessionStorage.getItem('username');
    const router = useRouter();

    const handleLogout = () => {
      ElMessage.success("退出成功");
      router.replace('/login');
      sessionStorage.clear();
    };

    return {
      handleLogout,
      username
    };
  }

};