import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, ref, reactive } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 岗位搜索框

const multiplelimit = ref(1);
const joboptions = ref([]);
const jobvalue = ref();
const job = ref(""); // 预支工资表格数据

const BannerData = ref(); // 弹窗

const dialogFormVisible = ref(false);
const dialogVisible = ref(false);
export default {
  name: 'BannerMan',

  setup() {
    //上传文件
    const imageList = ref(""); // 上传相关

    const fileList = reactive([]);
    const percent = ref(0); // 查询获取预支工资列表数据

    const getBanner = () => {
      axios.get(axiosUrl + "banner/all").then(response => {
        console.log(response);
        BannerData.value = response.data.data;
      });
    }; // 添加编辑所需数据


    const addUpData = reactive({
      id: null,
      jobId: jobvalue,
      image: imageList
    }); // 打开新增轮播图,清空数据

    const openBanner = () => {
      dialogFormVisible.value = true;
      addUpData.id = null, addUpData.jobId = null;
      addUpData.image = '';
      imageList.value = '';
    }; // 新增编辑方法


    const handleAddUp = () => {
      axios.post(axiosUrl + "banner/save", JSON.parse(JSON.stringify(addUpData))).then(response => {
        const result = response.data;

        if (result.success) {
          dialogFormVisible.value = false; // 重新加载列表

          getBanner({});
        } else {
          // eslint-disable-next-line no-undef
          ElMessage.error(result.message);
        }
      });
    }; // 获取行数据，填充编辑数据


    const rowData = row => {
      console.log(row);
      addUpData.id = JSON.parse(JSON.stringify(row)).id;
      addUpData.image = JSON.parse(JSON.stringify(row)).image;
    }; // 删除方法


    const handleDelete = () => {
      dialogVisible.value = false;
      axios.delete(axiosUrl + "banner/delete/" + addUpData.id).then(() => {
        // 重新加载列表
        getBanner({});
      });
      addUpData.id = null;
    }; //上传成功回调


    const handleSuccess = (res, file) => {
      console.log("handleSuccessres", res);
      console.log("handleSuccessfile", file);

      if (res) {
        ElMessage.success({
          message: "上传成功!"
        });
      } else {
        ElMessage.error({
          message: "上传失败，请重新上传"
        });
      }
    };

    const handleChange = (file, fileList) => {
      const arr = JSON.parse(JSON.stringify(fileList)).map(item => {
        if (item.response) {
          return item.response;
        }
      });
      imageList.value = arr + "";
      console.log("imageList.value ", imageList.value);
    }; // 上传时候的钩子


    const uploadProcess = event => {
      // console.log(event.percent);
      percent.value = Math.floor(event.percent);
    }; // 删除文件


    const beforeRemove = (file, fileList) => {
      let index = fileList.indexOf(file);
      fileList.splice(index, 1);
      axios.post(axiosUrl + "deleteFile", {
        pathName: file.response
      }).then(response => {
        console.log(response);
        ElMessage.success("删除成功");
      });
    }; // 添加编辑岗位搜索框方法


    const getJob = query => {
      if (query) {
        axios.get(axiosUrl + `job/all?name=${query}`).then(response => {
          job.value = response.data.data;
          joboptions.value = job.value.map(item => {
            return {
              jobvalue: `${item.id}`,
              label: `为您匹配到:${item.name}`,
              id: `${item.id}`
            };
          });
        });
      } else {
        joboptions.value = [];
      }
    };

    onMounted(() => {
      getBanner({});
    });
    return {
      BannerData,
      dialogFormVisible,
      dialogVisible,
      openBanner,
      addUpData,
      handleAddUp,
      handleDelete,
      getJob,
      multiplelimit,
      jobvalue,
      joboptions,
      fileList,
      handleSuccess,
      handleChange,
      uploadProcess,
      beforeRemove,
      rowData
    };
  }

};