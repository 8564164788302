import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 预支工资表格数据

const AdvanceData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'AdvanceSalary',

  setup() {
    // 查询获取预支工资列表数据
    const getAdvance = params => {
      axios.get(axiosUrl + "adsalary/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          name: params.name,
          startTime: params.startTime,
          endTime: params.endTime
        }
      }).then(response => {
        console.log(response);
        AdvanceData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      name: ''
    });
    const addTime = ref([]); // 查询方法

    const onSubmit = () => {
      getAdvance({
        page: currentPage.value,
        size: pageSize.value,
        name: formInline.name,
        startTime: addTime.value[0],
        endTime: addTime.value[1]
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getAdvance({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getAdvance({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const downloadAdsalary = () => {
      axios.get(axiosUrl + "adsalary/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    onMounted(() => {
      getAdvance({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      downloadAdsalary,
      AdvanceData,
      formInline,
      onSubmit,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      addTime
    };
  }

};