import router from './index'


// 路由守卫
router.beforeEach((to,from,next) => {
    if(sessionStorage.getItem('token')){
        next()
    }else{
        if(to.matched.some( function(item){return item.meta.loginRequire} )){
            next({ name: 'MyLogin' })
        }else{
            next() 
        }
    }
})
