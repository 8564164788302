import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_el_pagination = _resolveComponent("el-pagination");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_table, {
    data: $setup.ComplainData,
    border: "",
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "realname",
      label: "用户姓名"
    }), _createVNode(_component_el_table_column, {
      prop: "phone",
      label: "手机号"
    }), _createVNode(_component_el_table_column, {
      prop: "complain",
      label: "投诉内容"
    })]),
    _: 1
  }, 8, ["data"]), _createVNode(_component_el_pagination, {
    class: "pages",
    currentPage: $setup.currentPage,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => $setup.currentPage = $event),
    "page-size": $setup.pageSize,
    "onUpdate:page-size": _cache[1] || (_cache[1] = $event => $setup.pageSize = $event),
    "page-sizes": [10, 20, 50, 100],
    small: $setup.small,
    disabled: $setup.disabled,
    background: $setup.background,
    layout: "sizes, prev, pager, next",
    total: $setup.total,
    onSizeChange: $setup.handleSizeChange,
    onCurrentChange: $setup.handleCurrentChange
  }, null, 8, ["currentPage", "page-size", "small", "disabled", "background", "total", "onSizeChange", "onCurrentChange"])], 64);
}