import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 通过拒绝弹窗

const tongVisible = ref(false);
const jueVisible = ref(false); // vip用户表格数据

const VipData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'VipMan',

  setup() {
    // 查询获取用户列表数据
    const getVips = params => {
      axios.get(axiosUrl + "user/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname,
          ifapplyvip: 1
        }
      }).then(response => {
        console.log(response);
        VipData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      realname: ''
    }); // 查询方法

    const onSubmit = () => {
      getVips({
        page: currentPage.value,
        size: pageSize.value,
        realname: formInline.realname
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getVips({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getVips({
        page: currentPage.value,
        size: pageSize.value
      });
    }; // 编辑所需数据


    const upData = reactive({
      id: null
    }); // 获取提现行数据

    const rowData = row => {
      upData.id = JSON.parse(JSON.stringify(row)).id;
    }; //  通过拒绝


    const handletong = () => {
      axios.post(axiosUrl + "user/auditvip", {
        id: upData.id,
        vipstate: 2,
        ifvip: 1
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getVips({
        page: 1,
        size: pageSize.value
      });
      tongVisible.value = false;
    };

    const handlejue = () => {
      axios.post(axiosUrl + "user/auditvip", {
        id: upData.id,
        vipstate: 3,
        ifvip: 2
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getVips({
        page: 1,
        size: pageSize.value
      });
      jueVisible.value = false;
    };

    onMounted(() => {
      getVips({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      VipData,
      formInline,
      onSubmit,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      tongVisible,
      jueVisible,
      handletong,
      handlejue,
      rowData
    };
  }

};