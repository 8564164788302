import { createApp } from 'vue'
import App from './App.vue'
import * as Elicons from "@element-plus/icons-vue";
import router from './router'
import './router/premit'
import store from './store'
const app = createApp(App);



for (let name in Elicons) {
    app.component(name, Elicons[name]);
}



app.use(router).use(store).mount('#app')
