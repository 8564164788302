import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 用户搜索框

const multiplelimit = ref(1);
const useroptions = ref([]);
const uservalue = ref();
const loading = ref(false);
const user = ref(""); // 岗位搜索框

const joboptions = ref([]);
const jobvalue = ref();
const job = ref(""); // 预支工资表格数据

const RegisData = ref(); // 弹窗-添加相关

const dialogFormVisible = ref(false); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'RegisMan',

  setup() {
    // 查询获取预支工资列表数据
    const getRegis = params => {
      axios.get(axiosUrl + "regis/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          name: params.name,
          realname: params.realname
        }
      }).then(response => {
        console.log(response);
        RegisData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      name: '',
      realname: ''
    }); // 查询方法

    const onSubmit = () => {
      getRegis({
        page: currentPage.value,
        size: pageSize.value,
        name: formInline.name,
        realname: formInline.realname
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getRegis({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getRegis({
        page: currentPage.value,
        size: pageSize.value
      });
    }; // 添加编辑所需数据


    const addUpData = reactive({
      userId: uservalue,
      jobId: jobvalue,
      interviewTime: ''
    }); // 打开新增弹窗,清空数据

    const openRegis = () => {
      dialogFormVisible.value = true;
      addUpData.userId = null;
      addUpData.jobId = null;
      addUpData.interviewTime = '';
    }; // 新增方法


    const handleAddUp = () => {
      axios.post(axiosUrl + "regis/save", JSON.parse(JSON.stringify(addUpData))).then(response => {
        const result = response.data;
        console.log(result);

        if (result.success) {
          dialogFormVisible.value = false; // 重新加载列表

          getRegis({
            page: currentPage.value,
            size: pageSize.value
          });
        } else {
          // eslint-disable-next-line no-undef
          ElMessage.error(result.message);
        }
      });
    }; // 添加编辑表单的用户搜索框方法


    const getUser = query => {
      if (query) {
        axios.get(axiosUrl + `user/user?realname=${query}`).then(response => {
          console.log(response);
          user.value = response.data.data;
          useroptions.value = user.value.map(item => {
            return {
              uservalue: `${item.id}`,
              label: `为您匹配到:${item.realname}`,
              id: `${item.id}`
            };
          });
        });
      } else {
        useroptions.value = [];
      }
    }; // 添加编辑表单的岗位搜索框方法


    const getJob = query => {
      if (query) {
        axios.get(axiosUrl + `job/all?name=${query}`).then(response => {
          console.log(response);
          job.value = response.data.data;
          joboptions.value = job.value.map(item => {
            return {
              jobvalue: `${item.id}`,
              label: `为您匹配到:${item.name}`,
              id: `${item.id}`
            };
          });
        });
      } else {
        joboptions.value = [];
      }
    };

    const downloadRegis = () => {
      axios.get(axiosUrl + "regis/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    onMounted(() => {
      getRegis({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      RegisData,
      formInline,
      onSubmit,
      downloadRegis,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      dialogFormVisible,
      handleAddUp,
      openRegis,
      addUpData,
      getUser,
      loading,
      uservalue,
      useroptions,
      multiplelimit,
      getJob,
      joboptions,
      jobvalue
    };
  }

};