import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 状态下拉框选项

const options1 = [{
  value: '0',
  label: '收入'
}, {
  value: '1',
  label: '支出'
}];
const options2 = [{
  value: '0',
  label: '待审核'
}, {
  value: '1',
  label: '提现成功'
}, {
  value: '2',
  label: '提现失败'
}]; // 工资收入弹窗

const dialogImportVisible = ref(false);
const rdialogImportVisible = ref(false);
const ddialogImportVisible = ref(false); // 通过拒绝弹窗

const tongsVisible = ref(false);
const juesVisible = ref(false);
const tongrVisible = ref(false);
const juerVisible = ref(false);
const tongdVisible = ref(false);
const juedVisible = ref(false); // 页面切换，默认选中

const activeName = ref('first'); // 工资/在职奖励表格数据

const SalaryData = ref();
const RewardData = ref();
const DrecordData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
const rcurrentPage = ref(1);
const rpageSize = ref(10);
const rtotal = ref();
const dcurrentPage = ref(1);
const dpageSize = ref(10);
const dtotal = ref();
export default {
  name: 'CashMan',

  setup() {
    // 查询获取工资、在职奖励列表数据
    const getSalary = params => {
      axios.get(axiosUrl + "sarecord/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname,
          category: params.category,
          phone: params.phone,
          state: params.state,
          year: params.year,
          month: params.month
        }
      }).then(response => {
        console.log(response);
        SalaryData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    };

    const getReward = params => {
      axios.get(axiosUrl + "rrecord/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname,
          category: params.category,
          phone: params.phone,
          state: params.state,
          year: params.year,
          month: params.month
        }
      }).then(response => {
        console.log(response);
        RewardData.value = response.data.pageResp.list;
        rtotal.value = response.data.pageResp.total;
      });
    };

    const getDrecord = params => {
      axios.get(axiosUrl + "drecord/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname,
          category: params.category,
          phone: params.phone,
          state: params.state
        }
      }).then(response => {
        console.log(response);
        DrecordData.value = response.data.pageResp.list;
        dtotal.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      realname: '',
      phone: null,
      category: null,
      state: null,
      year: null,
      month: null
    });
    const rformInline = reactive({
      realname: '',
      phone: null,
      category: null,
      state: null,
      year: null,
      month: null
    });
    const dformInline = reactive({
      realname: '',
      phone: null,
      category: null,
      state: null
    }); // 查询方法

    const onSubmit = () => {
      getSalary({
        page: currentPage.value,
        size: pageSize.value,
        realname: formInline.realname,
        phone: formInline.phone,
        category: formInline.category,
        state: formInline.state,
        year: formInline.year,
        month: formInline.month
      });
    };

    const ronSubmit = () => {
      getReward({
        page: rcurrentPage.value,
        size: rpageSize.value,
        realname: rformInline.realname,
        phone: rformInline.phone,
        category: rformInline.category,
        state: rformInline.state,
        year: rformInline.year,
        month: rformInline.month
      });
    };

    const donSubmit = () => {
      getDrecord({
        page: dcurrentPage.value,
        size: dpageSize.value,
        realname: dformInline.realname,
        phone: dformInline.phone,
        category: dformInline.category,
        state: dformInline.state
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getSalary({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getSalary({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const rhandleSizeChange = val => {
      rpageSize.value = val;
      getReward({
        page: rcurrentPage.value,
        size: rpageSize.value
      });
    };

    const rhandleCurrentChange = val => {
      rcurrentPage.value = val;
      getReward({
        page: rcurrentPage.value,
        size: rpageSize.value
      });
    };

    const dhandleSizeChange = val => {
      dpageSize.value = val;
      getDrecord({
        page: dcurrentPage.value,
        size: dpageSize.value
      });
    };

    const dhandleCurrentChange = val => {
      dcurrentPage.value = val;
      getDrecord({
        page: dcurrentPage.value,
        size: dpageSize.value
      });
    }; // 编辑所需数据


    const supData = reactive({
      id: null,
      amount: null,
      userId: null
    });
    const rupData = reactive({
      id: null,
      amount: null,
      userId: null
    });
    const dupData = reactive({
      id: null,
      amount: null,
      userId: null
    }); // 获取提现行数据

    const srowData = row => {
      console.log(row);
      supData.id = JSON.parse(JSON.stringify(row)).id;
      supData.amount = JSON.parse(JSON.stringify(row)).amount;
      supData.userId = JSON.parse(JSON.stringify(row)).userId;
    };

    const rrowData = row => {
      console.log(row);
      rupData.id = JSON.parse(JSON.stringify(row)).id;
      rupData.amount = JSON.parse(JSON.stringify(row)).amount;
      rupData.userId = JSON.parse(JSON.stringify(row)).userId;
    };

    const drowData = row => {
      console.log(row);
      dupData.id = JSON.parse(JSON.stringify(row)).id;
      dupData.amount = JSON.parse(JSON.stringify(row)).amount;
      dupData.userId = JSON.parse(JSON.stringify(row)).userId;
    }; //  通过拒绝


    const handlestong = () => {
      axios.post(axiosUrl + "sarecord/audit", {
        id: supData.id,
        state: 1,
        userId: supData.userId,
        amount: supData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getSalary({
        page: 1,
        size: pageSize.value
      });
      tongsVisible.value = false;
    };

    const handlesjue = () => {
      axios.post(axiosUrl + "sarecord/audit", {
        id: supData.id,
        state: 2,
        userId: supData.userId,
        amount: supData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getSalary({
        page: 1,
        size: pageSize.value
      });
      juesVisible.value = false;
    };

    const handlertong = () => {
      axios.post(axiosUrl + "rrecord/audit", {
        id: rupData.id,
        state: 1,
        userId: rupData.userId,
        amount: rupData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getReward({
        page: 1,
        size: rpageSize.value
      });
      tongrVisible.value = false;
    };

    const handlerjue = () => {
      axios.post(axiosUrl + "rrecord/audit", {
        id: rupData.id,
        state: 2,
        userId: rupData.userId,
        amount: rupData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getReward({
        page: 1,
        size: rpageSize.value
      });
      juerVisible.value = false;
    };

    const handledtong = () => {
      axios.post(axiosUrl + "drecord/audit", {
        id: dupData.id,
        state: 1,
        userId: dupData.userId,
        amount: dupData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getDrecord({
        page: 1,
        size: dpageSize.value
      });
      tongdVisible.value = false;
    };

    const handledjue = () => {
      axios.post(axiosUrl + "drecord/audit", {
        id: dupData.id,
        state: 2,
        userId: dupData.userId,
        amount: dupData.amount
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getDrecord({
        page: 1,
        size: dpageSize.value
      });
      juedVisible.value = false;
    }; //工资批量导入


    const uploadRef = ref();

    const handleSuccess = response => {
      if (response.success) {
        ElMessage.success("批量导入成功");
        getSalary({
          page: 1,
          size: pageSize.value
        });
      } else {
        ElMessage.error(response.message);
        getSalary({
          page: 1,
          size: pageSize.value
        });
      }

      console.log(uploadRef);
      uploadRef.value.clearFiles();
      dialogImportVisible.value = false;
    };

    const handleError = () => {
      dialogImportVisible.value = false;
      ElMessage.success("文件上传服务器失败，请重新上传");
      getSalary({
        page: 1,
        size: pageSize.value
      });
    }; // 下载工资收入导入导入模板


    const downloadFile = () => {
      axios.get(axiosUrl + "sarecord/download").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    }; //在职奖励批量导入


    const ruploadRef = ref();

    const rhandleSuccess = response => {
      if (response.success) {
        ElMessage.success("批量导入成功");
        getReward({
          page: 1,
          size: pageSize.value
        });
      } else {
        ElMessage.error(response.message);
        getReward({
          page: 1,
          size: pageSize.value
        });
      }

      ruploadRef.value.clearFiles();
      rdialogImportVisible.value = false;
    };

    const rhandleError = () => {
      rdialogImportVisible.value = false;
      ElMessage.success("文件上传服务器失败，请重新上传");
      getReward({
        page: 1,
        size: pageSize.value
      });
    }; // 下载在职奖励导入导入模板


    const rdownloadFile = () => {
      axios.get(axiosUrl + "rrecord/download").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    }; //分销批量导入


    const duploadRef = ref();

    const dhandleSuccess = response => {
      if (response.success) {
        ElMessage.success("批量导入成功");
        getDrecord({
          page: 1,
          size: pageSize.value
        });
      } else {
        ElMessage.error(response.message);
        getDrecord({
          page: 1,
          size: pageSize.value
        });
      }

      duploadRef.value.clearFiles();
      ddialogImportVisible.value = false;
    };

    const dhandleError = () => {
      ddialogImportVisible.value = false;
      ElMessage.success("文件上传服务器失败，请重新上传");
      getDrecord({
        page: 1,
        size: pageSize.value
      });
    }; // 下载分销导入导入模板


    const ddownloadFile = () => {
      axios.get(axiosUrl + "drecord/download").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    const downloadDrecord = () => {
      axios.get(axiosUrl + "drecord/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    const downloadRrecord = () => {
      axios.get(axiosUrl + "rrecord/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    const downloadSarecord = () => {
      axios.get(axiosUrl + "sarecord/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    onMounted(() => {
      getSalary({
        page: 1,
        size: pageSize.value
      });
      getReward({
        page: 1,
        size: rpageSize.value
      });
      getDrecord({
        page: 1,
        size: dpageSize.value
      });
    });
    return {
      downloadDrecord,
      downloadRrecord,
      downloadSarecord,
      ddialogImportVisible,
      duploadRef,
      dhandleSuccess,
      dhandleError,
      ddownloadFile,
      SalaryData,
      RewardData,
      formInline,
      onSubmit,
      currentPage,
      activeName,
      tongrVisible,
      juerVisible,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      srowData,
      handlertong,
      handlerjue,
      tongsVisible,
      juesVisible,
      handlestong,
      handlesjue,
      rrowData,
      dialogImportVisible,
      rdialogImportVisible,
      handleSuccess,
      handleError,
      downloadFile,
      rhandleSuccess,
      rhandleError,
      rdownloadFile,
      options1,
      options2,
      rcurrentPage,
      rpageSize,
      rtotal,
      rhandleSizeChange,
      rhandleCurrentChange,
      rformInline,
      ronSubmit,
      DrecordData,
      dformInline,
      drowData,
      donSubmit,
      tongdVisible,
      juedVisible,
      handledtong,
      handledjue,
      dcurrentPage,
      dpageSize,
      dtotal,
      dhandleSizeChange,
      dhandleCurrentChange
    };
  }

};