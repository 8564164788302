import { createRouter, createWebHashHistory } from 'vue-router'
import LongJob from '../views/LongJob.vue'
import UserMan from '../views/UserMan.vue'
import CashMan from '../views/CashMan.vue'
import ShortJob from '../views/ShortJob.vue'
import AdvanceSalary from '../views/AdvanceSalary.vue'
import SuComplain from '../views/SuComplain.vue'
import SalaryMan from '../views/SalaryMan.vue'
import RegisMan from '../views/RegisMan.vue'
import BannerMan from '../views/BannerMan.vue'
import VipMan from '../views/VipMan.vue'
 
import MyHeader from '../views/MyHeader.vue'
import MyLogin from '../views/MyLogin.vue'
import MyAdmin from '../layout/MyAdmin.vue'
import UpdatePass from '../views/UpdatePass.vue'

// 默认路由
export const defluateRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'MyLogin',
    component: MyLogin
  },
  {
    path: '/admin',
    name: 'MyAdmin',
    component: MyAdmin,
    meta: {
      loginRequire: true,
    },
    children:[
      {
        path: 'longjob',
        name: 'LongJob',
        component: LongJob,
        meta: {
          title: '长期岗位',
          icon:'m249.6 417.088 319.744 43.072 39.168 310.272L845.12 178.88 249.6 417.088zm-129.024 47.168a32 32 0 0 1-7.68-61.44l777.792-311.04a32 32 0 0 1 41.6 41.6l-310.336 775.68a32 32 0 0 1-61.44-7.808L512 516.992l-391.424-52.736z'
        },
      },
      {
        path: 'shortJob',
        name: 'ShortJob',
        component: ShortJob,
        meta: {
          title: '短期岗位',
          icon:'M128.896 736H96a32 32 0 0 1-32-32V224a32 32 0 0 1 32-32h576a32 32 0 0 1 32 32v96h164.544a32 32 0 0 1 31.616 27.136l54.144 352A32 32 0 0 1 922.688 736h-91.52a144 144 0 1 1-286.272 0H415.104a144 144 0 1 1-286.272 0zm23.36-64a143.872 143.872 0 0 1 239.488 0H568.32c17.088-25.6 42.24-45.376 71.744-55.808V256H128v416h24.256zm655.488 0h77.632l-19.648-128H704v64.896A144 144 0 0 1 807.744 672zm48.128-192-14.72-96H704v96h151.872zM688 832a80 80 0 1 0 0-160 80 80 0 0 0 0 160zm-416 0a80 80 0 1 0 0-160 80 80 0 0 0 0 160z'
        },
      },
      {
        path: 'userman',
        name: 'UserMan',
        component: UserMan,
        meta: {
          title: '用户管理',
          icon:'M512 512a192 192 0 1 0 0-384 192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512 256 256 0 0 1 0 512zm320 320v-96a96 96 0 0 0-96-96H288a96 96 0 0 0-96 96v96a32 32 0 1 1-64 0v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 1 1-64 0z'
        },
      },
      {
        path: 'regisman',
        name: 'RegisMan',
        component: RegisMan,
        meta: {
          title: '报名记录',
          icon:'M192 128v768h640V128H192zm-32-64h704a32 32 0 0 1 32 32v832a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm160 448h384v64H320v-64zm0-192h192v64H320v-64zm0 384h384v64H320v-64z'
        },
      },
      {
        path: 'myheader',
        name: 'MyHeader',
        component: MyHeader,
        meta: {
          title: '负责人管理',
          icon:'M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z'
        },
      },
      {
        path: 'cashman',
        name: 'CashMan',
        component: CashMan,
        meta: {
          title: '钱包管理',
          icon:'M688 512a112 112 0 1 0 0 224h208v160H128V352h768v160H688zm32 160h-32a48 48 0 0 1 0-96h32a48 48 0 0 1 0 96zm-80-544 128 160H384l256-160z'
        },
      },{
        path: 'salaryman',
        name: 'SalaryMan',
        component: SalaryMan,
        meta: {
          title: '工资条管理',
          icon:'m161.92 580.736 29.888 58.88C171.328 659.776 160 681.728 160 704c0 82.304 155.328 160 352 160s352-77.696 352-160c0-22.272-11.392-44.16-31.808-64.32l30.464-58.432C903.936 615.808 928 657.664 928 704c0 129.728-188.544 224-416 224S96 833.728 96 704c0-46.592 24.32-88.576 65.92-123.264z"></path><path fill="currentColor" d="m161.92 388.736 29.888 58.88C171.328 467.84 160 489.792 160 512c0 82.304 155.328 160 352 160s352-77.696 352-160c0-22.272-11.392-44.16-31.808-64.32l30.464-58.432C903.936 423.808 928 465.664 928 512c0 129.728-188.544 224-416 224S96 641.728 96 512c0-46.592 24.32-88.576 65.92-123.264z"></path><path fill="currentColor" d="M512 544c-227.456 0-416-94.272-416-224S284.544 96 512 96s416 94.272 416 224-188.544 224-416 224zm0-64c196.672 0 352-77.696 352-160S708.672 160 512 160s-352 77.696-352 160 155.328 160 352 160z'
        },
      },{
        path: 'vipman',
        name: 'VipMan',
        component: VipMan,
        meta: {
          title: 'VIP管理',
          icon:'M283.84 867.84 512 747.776l228.16 119.936a6.4 6.4 0 0 0 9.28-6.72l-43.52-254.08 184.512-179.904a6.4 6.4 0 0 0-3.52-10.88l-255.104-37.12L517.76 147.904a6.4 6.4 0 0 0-11.52 0L392.192 379.072l-255.104 37.12a6.4 6.4 0 0 0-3.52 10.88L318.08 606.976l-43.584 254.08a6.4 6.4 0 0 0 9.28 6.72z'
        },
      },
      
      {
        path: 'advancesalary',
        name: 'AdvanceSalary',
        component: AdvanceSalary,
        meta: {
          title: '提前预支',
          icon:'M64 320h896v64H64v-64zm0 128h896v64H64v-64zm128 192h256v64H192z'
        },
      },
      {
        path: 'sucomplain',
        name: 'SuComplain',
        component: SuComplain,
        meta: {
          title: '投诉建议',
          icon:'M737.216 357.952 704 349.824l-11.776-32a192.064 192.064 0 0 0-367.424 23.04l-8.96 39.04-39.04 8.96A192.064 192.064 0 0 0 320 768h368a207.808 207.808 0 0 0 207.808-208 208.32 208.32 0 0 0-158.592-202.048zm15.168-62.208A272.32 272.32 0 0 1 959.744 560a271.808 271.808 0 0 1-271.552 272H320a256 256 0 0 1-57.536-505.536 256.128 256.128 0 0 1 489.92-30.72z'
        },
      },
      {
        path: 'bannerman',
        name: 'BannerMan',
        component: BannerMan,
        meta: {
          title: '轮播图管理',
          icon:'M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z'
        },
      },
      {
        path: 'updatepass',
        name: 'UpdatePass',
        component: UpdatePass,
        meta: {
          title: '修改密码',
          icon:'M777.344 822.656a32 32 0 0 0 45.312-45.312l-544-544a32 32 0 0 0-45.312 45.312l544 544z'
        },
      },
    ]
  },

]
   
const router = createRouter({
  history: createWebHashHistory(),
  routes: defluateRoutes
})


export default router