import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { provinceAndCityData, CodeToText } from 'element-china-area-data';
import { axiosUrl } from '../common/baseConst'; // 岗位显示与隐藏

const viewVisible = ref(false);
const nviewVisible = ref(false); // 负责人搜索框

const multiplelimit = ref(1);
const headeroptions = ref([]);
const headervalue = ref();
const header = ref(""); // 城市数据

const selectedOptions = ref(""); // 单选数据

const payTyperadio = ref("");
const eatLiveradio = ref("");
const workRestradio = ref("");
const rtyperadio = ref(null);
const dtyperadio = ref(null);
const checkList = ref([]);
const icheckList = ref([]); // 编辑左对齐

const labelPosition = ref('left'); // 弹窗-添加相关

const dialogFormVisible = ref(false);
const formLabelWidth = '140px';
const headerVisible = ref(false); // 删除弹窗框相关

const dialogVisible = ref(false); // 岗位表格数据

const LongJobData = ref();
const HeaderData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'LongJob',

  setup() {
    // const dataMap = reactive({
    //   latitude: '', //纬度
    //   lngitude: '', //经度
    // });
    const latitude = ref('');
    const lngitude = ref('');
    const tigi = ref('');
    const address = ref(''); //上传文件

    const imageList = ref("");
    const vodeos = ref(""); // 上传相关

    const fileList = reactive([]);
    const percent = ref(0); // 查询获取岗位列表数据

    const getLongJob = params => {
      axios.get(axiosUrl + "job/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          category: 0,
          name: params.name,
          header: params.header
        }
      }).then(response => {
        console.log(response);
        LongJobData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询岗位所有负责人


    const getHeaders = id => {
      headerVisible.value = true;
      headervalue.value = null;
      axios.get(axiosUrl + "jobHeader/all", {
        params: {
          jobId: id
        }
      }).then(response => {
        console.log(response);
        HeaderData.value = response.data.data;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      name: '',
      header: ''
    }); // 查询方法

    const onSubmit = () => {
      getLongJob({
        page: currentPage.value,
        size: pageSize.value,
        name: formInline.name,
        header: formInline.header
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getLongJob({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getLongJob({
        page: currentPage.value,
        size: pageSize.value
      });
    }; // 获取行数据，填充编辑数据


    const rowData = row => {
      console.log("row", row); //  企业福利处理

      if (row.welfare != null) {
        const str1 = row.welfare.substring(1, row.welfare.length - 1);
        checkList.value = str1.split(",");
      } // 行业处理


      if (row.industry != null) {
        const str2 = row.industry.substring(1, row.industry.length - 1);
        icheckList.value = str2.split(",");
      }

      addUpData.id = JSON.parse(JSON.stringify(row)).id;
      headerUpData.jobId = JSON.parse(JSON.stringify(row)).id;
      addUpData.name = JSON.parse(JSON.stringify(row)).name;
      addUpData.salary = JSON.parse(JSON.stringify(row)).salary;
      addUpData.jobRequire = JSON.parse(JSON.stringify(row)).jobRequire;
      addUpData.jobTime = JSON.parse(JSON.stringify(row)).jobTime;
      addUpData.duty = JSON.parse(JSON.stringify(row)).duty;
      addUpData.rtypeCount = JSON.parse(JSON.stringify(row)).rtypeCount;
      addUpData.rtypeTime = JSON.parse(JSON.stringify(row)).rtypeTime;
      addUpData.phone = JSON.parse(JSON.stringify(row)).phone;
      addUpData.city = JSON.parse(JSON.stringify(row)).city;
      addUpData.workplace = JSON.parse(JSON.stringify(row)).workplace;
      addUpData.salaryStr = JSON.parse(JSON.stringify(row)).salaryStr;
      addUpData.address = JSON.parse(JSON.stringify(row)).address;
      addUpData.dcount = JSON.parse(JSON.stringify(row)).dcount;
      addUpData.parDcount = JSON.parse(JSON.stringify(row)).parDcount;
      rtyperadio.value = JSON.parse(JSON.stringify(row)).rtype;
      dtyperadio.value = JSON.parse(JSON.stringify(row)).dtype;
      payTyperadio.value = JSON.parse(JSON.stringify(row)).payType;
      eatLiveradio.value = JSON.parse(JSON.stringify(row)).eatLive;
      workRestradio.value = JSON.parse(JSON.stringify(row)).workRest;
      addUpData.images = JSON.parse(JSON.stringify(row)).images;
      addUpData.vodeos = JSON.parse(JSON.stringify(row)).vodeos;
      addUpData.sequence = JSON.parse(JSON.stringify(row)).sequence;
    }; // 添加编辑所需数据


    const addUpData = reactive({
      id: null,
      name: '',
      salary: '',
      category: 0,
      salaryStr: '',
      payType: payTyperadio,
      eatLive: eatLiveradio,
      workRest: workRestradio,
      temwelfare: checkList,
      temindustry: icheckList,
      jobRequire: '',
      jobTime: '',
      duty: '',
      rtype: rtyperadio,
      rtypeCount: '',
      rtypeTime: '',
      images: imageList,
      vodeos: vodeos,
      phone: '',
      city: selectedOptions,
      workplace: tigi,
      address: address,
      dcount: null,
      parDcount: null,
      dtype: dtyperadio,
      sequence: null
    });
    const headerUpData = reactive({
      id: null,
      jobId: null,
      headerId: headervalue
    }); // 打开新增弹窗,清空数据

    const openLongJob = () => {
      dialogFormVisible.value = true;
      addUpData.id = null, addUpData.name = '';
      addUpData.salary = '';
      addUpData.salaryStr = '';
      payTyperadio.value = '';
      eatLiveradio.value = '';
      workRestradio.value = '';
      checkList.value = [];
      icheckList.value = [];
      rtyperadio.value = '';
      dtyperadio.value = '', addUpData.jobRequire = '';
      addUpData.jobTime = '';
      addUpData.duty = '';
      addUpData.rtypeCount = '';
      addUpData.rtypeTime = '';
      addUpData.phone = '';
      addUpData.city = '';
      addUpData.workplace = '';
      addUpData.address = '';
      addUpData.dcount = null;
      addUpData.parDcount = null;
      addUpData.vodeos = '';
      addUpData.images = '';
      addUpData.sequence = null;
    }; // 新增编辑方法


    const handleAddUp = () => {
      axios.post(axiosUrl + "job/save", JSON.parse(JSON.stringify(addUpData))).then(response => {
        const result = response.data;
        console.log(result);

        if (result.success) {
          dialogFormVisible.value = false; // 重新加载列表

          getLongJob({
            page: currentPage.value,
            size: pageSize.value
          });
        } else {
          // eslint-disable-next-line no-undef
          ElMessage.error(result.message);
        }

        location.reload();
      });
    }; // 负责人添加方法


    const headerAddUp = () => {
      console.log(headerUpData.jobId);
      axios.post(axiosUrl + "jobHeader/save", JSON.parse(JSON.stringify(headerUpData))).then(response => {
        const result = response.data;
        console.log(result);

        if (result.success) {
          // 重新加载列表
          getHeaders(headerUpData.jobId);
        } else {
          // eslint-disable-next-line no-undef
          ElMessage.error(result.message);
        }
      });
    }; // 删除方法


    const handleDelete = () => {
      dialogVisible.value = false;
      axios.delete(axiosUrl + "job/delete/" + addUpData.id).then(() => {
        // 重新加载列表
        getLongJob({
          page: currentPage.value,
          size: pageSize.value
        });
      });
      addUpData.id = null;
    }; // 负责人删除方法


    const headerDelete = (id, jobId) => {
      console.log(id, jobId);
      dialogVisible.value = false;
      axios.delete(axiosUrl + "jobHeader/delete/" + id).then(() => {
        // 重新加载列表
        getHeaders(jobId);
      });
      addUpData.id = null;
    }; //上传成功回调


    const handleSuccess = (res, file) => {
      console.log("handleSuccessres", res);
      console.log("handleSuccessfile", file);

      if (res) {
        ElMessage.success({
          message: "上传成功!"
        });
      } else {
        ElMessage.error({
          message: "上传失败，请重新上传"
        });
      }
    };

    const handlevsuccess = (res, file) => {
      console.log("handleSuccessres", res);
      console.log("handleSuccessfile", file);

      if (res) {
        ElMessage.success({
          message: "上传成功!"
        });
      } else {
        ElMessage.error({
          message: "上传失败，请重新上传"
        });
      }
    };

    const handleChange = (file, fileList) => {
      const arr = JSON.parse(JSON.stringify(fileList)).map(item => {
        if (item.response) {
          return item.response;
        }
      });
      imageList.value = "[" + arr + "]";
      console.log("imageList.value ", imageList.value);
    };

    const handlevChange = file => {
      vodeos.value = file.response;
    }; // 上传时候的钩子


    const uploadProcess = event => {
      // console.log(event.percent);
      percent.value = Math.floor(event.percent);
    }; // 删除文件


    const beforeRemove = (file, fileList) => {
      let index = fileList.indexOf(file);
      fileList.splice(index, 1);
      axios.post(axiosUrl + "deleteFile", {
        pathName: file.response
      }).then(response => {
        console.log(response);
        ElMessage.success("删除成功");
      });
    }; // 将城市代码转为文字


    const cityChange = value => {
      selectedOptions.value = CodeToText[value[0]] + CodeToText[value[1]];
    }; // 添加编辑表单的负责人搜索框方法


    const getHeader = query => {
      if (query) {
        axios.get(axiosUrl + `header/all?name=${query}`).then(response => {
          console.log(response);
          header.value = response.data.data;
          headeroptions.value = header.value.map(item => {
            return {
              headervalue: `${item.id}`,
              label: `为您匹配到:${item.name}`,
              id: `${item.id}`
            };
          });
        });
      } else {
        headeroptions.value = [];
      }
    }; // 地图


    const querySearch = (queryString, cb) => {
      try {
        let params = {
          keyword: queryString,
          key: "LTYBZ-XSYKW-VSORE-RBPLS-6525S-O5BDN" // 填申请到的腾讯key

        };
        axios.get('/api/place/v1/suggestion', {
          params
        }).then(response => {
          const data = response.data.data;
          const results = data.map(item => {
            let obj = {
              value: item.address,
              address: item.address
            };
            return obj;
          });
          cb(results);
        });
      } catch (e) {
        console.log(e);
      }
    }; // 获取搜索经纬度


    const getLocations = async () => {
      try {
        let params = {
          key: "LTYBZ-XSYKW-VSORE-RBPLS-6525S-O5BDN",
          // 填申请到的腾讯key
          address: address.value //具体的地址

        };
        let result = await axios.get('/api/geocoder/v1', {
          params
        });
        latitude.value = result.data.result.location.lat;
        lngitude.value = result.data.result.location.lng;
        tigi.value = '[' + latitude.value + ',' + lngitude.value + ']';
        console.log(tigi.value, tigi.value); // dataMap.map.setCenter(new TMap.LatLng(dataMap.latitude,dataMap.lngitude));
        // reloadMap();
      } catch (error) {
        console.log(error);
      }
    }; //  岗位上下架


    const handleview = () => {
      axios.post(axiosUrl + "job/changeview", {
        id: addUpData.id,
        viewstate: 1
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getLongJob({
        page: 1,
        size: pageSize.value
      });
      viewVisible.value = false;
    };

    const handlenview = () => {
      axios.post(axiosUrl + "job/changeview", {
        id: addUpData.id,
        viewstate: 2
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getLongJob({
        page: 1,
        size: pageSize.value
      });
      nviewVisible.value = false;
    };

    const downloadJob = () => {
      axios.get(axiosUrl + "job/write", {
        params: {
          category: 0
        }
      }).then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    onMounted(() => {
      getLongJob({
        page: 1,
        size: pageSize.value
      }); // init()
    });
    return {
      downloadJob,
      headerDelete,
      LongJobData,
      formInline,
      onSubmit,
      dialogFormVisible,
      handleAddUp,
      formLabelWidth,
      addUpData,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      rowData,
      fileList,
      provinceAndCityData,
      cityChange,
      selectedOptions,
      handleSuccess,
      handleChange,
      uploadProcess,
      beforeRemove,
      handleDelete,
      dialogVisible,
      labelPosition,
      openLongJob,
      payTyperadio,
      eatLiveradio,
      workRestradio,
      rtyperadio,
      getHeader,
      multiplelimit,
      headeroptions,
      headervalue,
      querySearch,
      address,
      getLocations,
      dtyperadio,
      handlevsuccess,
      handlevChange,
      viewVisible,
      nviewVisible,
      handleview,
      handlenview,
      checkList,
      icheckList,
      headerVisible,
      headerAddUp,
      headerUpData,
      HeaderData,
      getHeaders
    };
  }

};