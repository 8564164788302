import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 用户表格数据

const UserData = ref();
const parVisible = ref(false); // 弹窗-添加相关

const dialogFormVisible = ref(false); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'UserMan',

  setup() {
    const rowData = row => {
      console.log(row);
      addUpData.id = JSON.parse(JSON.stringify(row)).id;
    }; // 查询获取用户列表数据


    const getUsers = params => {
      axios.get(axiosUrl + "user/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname
        }
      }).then(response => {
        console.log(response);
        UserData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      realname: ''
    }); // 查询方法

    const onSubmit = () => {
      getUsers({
        page: currentPage.value,
        size: pageSize.value,
        realname: formInline.realname
      });
    }; // 添加编辑所需数据


    const addUpData = reactive({
      id: null,
      phone: null,
      parentid: null,
      realname: ''
    }); // 打开新增弹窗,清空数据

    const openUser = () => {
      dialogFormVisible.value = true;
      addUpData.id = null;
      addUpData.phone = null;
      addUpData.parentid = null;
      addUpData.realname = '';
    }; // 新增方法


    const handleAddUp = () => {
      axios.post(axiosUrl + "user/save", JSON.parse(JSON.stringify(addUpData))).then(response => {
        const result = response.data;
        console.log(result);

        if (result.success) {
          dialogFormVisible.value = false; // 重新加载列表

          getUsers({
            page: currentPage.value,
            size: pageSize.value
          });
        } else {
          // eslint-disable-next-line no-undef
          ElMessage.error(result.message);
        }
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getUsers({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getUsers({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handlechange = () => {
      axios.post(axiosUrl + "user/partner", {
        id: addUpData.id,
        ifpartner: 1
      }).then(response => {
        console.log(response);

        if (response.data.success) {
          ElMessage.success("操作成功");
        } else {
          ElMessage.error("操作失败");
        }
      });
      getUsers({
        page: 1,
        size: pageSize.value
      });
      parVisible.value = false;
    };

    const downloadUser = () => {
      axios.get(axiosUrl + "user/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    };

    onMounted(() => {
      getUsers({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      UserData,
      formInline,
      onSubmit,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      addUpData,
      handleAddUp,
      dialogFormVisible,
      openUser,
      parVisible,
      rowData,
      handlechange,
      downloadUser
    };
  }

};