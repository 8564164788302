import { onMounted, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst'; // 预支工资表格数据

const ComplainData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'SuComplain',

  setup() {
    // 查询获取预支工资列表数据
    const getComplain = params => {
      axios.get(axiosUrl + "complain/pagelist", {
        params: {
          page: params.page,
          size: params.size
        }
      }).then(response => {
        console.log(response);
        ComplainData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据
    // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getComplain({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getComplain({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    onMounted(() => {
      getComplain({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      ComplainData,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange
    };
  }

};