import MyHeader from '../components/MyHeader.vue';
import MyAside from '../components/MyAside.vue';
export default {
  name: 'MyAdmin',
  comments: {
    MyHeader,
    MyAside
  },

  setup() {}

};