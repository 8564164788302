import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst';
const ids = ref([]); // 删除弹窗框相关

const dialogVisible = ref(false);
const dialogVisibledes = ref(false); // 批量导入弹窗

const dialogImportVisible = ref(false); // 预支工资表格数据

const SalaryData = ref(); // 分页相关数据

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(true);
const disabled = ref(false);
const total = ref();
export default {
  name: 'SalaryMan',

  setup() {
    // 查询获取预支工资列表数据
    const getSalary = params => {
      axios.get(axiosUrl + "salary/pagelist", {
        params: {
          page: params.page,
          size: params.size,
          realname: params.realname,
          phone: params.phone,
          year: params.year,
          month: params.month
        }
      }).then(response => {
        console.log(response);
        SalaryData.value = response.data.pageResp.list;
        total.value = response.data.pageResp.total;
      });
    }; // 查询输入框数据


    const formInline = reactive({
      realname: '',
      phone: null,
      year: null,
      month: null
    }); // 查询方法

    const onSubmit = () => {
      getSalary({
        page: currentPage.value,
        size: pageSize.value,
        realname: formInline.realname,
        phone: formInline.phone,
        year: formInline.year,
        month: formInline.month
      });
    }; // 分页相关方法


    const handleSizeChange = val => {
      pageSize.value = val;
      getSalary({
        page: currentPage.value,
        size: pageSize.value
      });
    };

    const handleCurrentChange = val => {
      currentPage.value = val;
      getSalary({
        page: currentPage.value,
        size: pageSize.value
      });
    }; // 获取行数据，填充编辑数据


    const rowData = row => {
      console.log(row);
      addUpData.id = JSON.parse(JSON.stringify(row)).id;
    }; // 添加编辑所需数据


    const addUpData = reactive({
      id: null
    }); // 文件上传成功失败回调

    const uploadRef = ref();

    const handleSuccess = response => {
      if (response.success) {
        ElMessage.success("批量导入成功");
        getSalary({
          page: 1,
          size: pageSize.value
        });
      } else {
        ElMessage.error(response.message);
        getSalary({
          page: 1,
          size: pageSize.value
        });
      }

      console.log(uploadRef);
      uploadRef.value.clearFiles();
      dialogImportVisible.value = false;
    };

    const handleError = () => {
      dialogImportVisible.value = false;
      ElMessage.success("文件上传服务器失败，请重新上传");
      getSalary({
        page: 1,
        size: pageSize.value
      });
    }; // 下载工资条导入模板


    const downloadFile = () => {
      axios.get(axiosUrl + "salary/download").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    }; // 下载工资条


    const downloadSalary = () => {
      axios.get(axiosUrl + "salary/write").then(res => {
        const a = document.createElement('a');
        a.href = res.request.responseURL;
        a.click();
      }).catch(() => {
        alert("请求出错");
      });
    }; // 删除方法


    const handleDelete = () => {
      dialogVisible.value = false;
      axios.delete(axiosUrl + "salary/delete/" + addUpData.id).then(() => {
        // 重新加载列表
        getSalary({
          page: currentPage.value,
          size: pageSize.value
        });
      });
      addUpData.id = null;
    };

    const handleSelectionChange = val => {
      val.map(item => {
        console.log(item);
        ids.value.push(item.id);
      });
    }; // 批量删除方法


    const handleDels = () => {
      dialogVisibledes.value = false;
      axios.delete(axiosUrl + "salary/dels/" + ids.value).then(() => {
        // 重新加载列表
        getSalary({
          page: currentPage.value,
          size: pageSize.value
        });
      });
      ids.value = [];
    };

    onMounted(() => {
      getSalary({
        page: 1,
        size: pageSize.value
      });
    });
    return {
      handleSelectionChange,
      dialogVisibledes,
      handleDels,
      rowData,
      dialogVisible,
      handleDelete,
      downloadFile,
      downloadSalary,
      handleError,
      handleSuccess,
      dialogImportVisible,
      SalaryData,
      formInline,
      onSubmit,
      currentPage,
      small,
      background,
      disabled,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange
    };
  }

};