import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { reactive, ref } from 'vue';
import axios from 'axios';
import { axiosUrl } from '../common/baseConst';
export default {
  name: 'UpdatePass',

  setup() {
    const rules = reactive({
      password: [{
        required: true,
        message: '密码必须输入',
        trigger: 'blur'
      }],
      agingPassword: [{
        required: true,
        message: '密码必须输入',
        trigger: 'blur'
      }]
    });
    const labelPosition = ref('right');
    const formLabelAlign = reactive({
      username: sessionStorage.getItem('username'),
      password: '',
      agingPassword: ''
    });

    const upPass = () => {
      axios.post(axiosUrl + "admin/uppass", JSON.parse(JSON.stringify(formLabelAlign))).then(response => {
        console.log(response);

        if (response.data.success == true) {
          ElMessage.success("修改成功");
          formLabelAlign.password = '';
          formLabelAlign.agingPassword = '';
        } else {
          ElMessage.error(response.data.message);
        }
      });
    };

    return {
      labelPosition,
      formLabelAlign,
      rules,
      upPass
    };
  }

};