import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { defluateRoutes } from '../router/index';
export default {
  name: 'MyAside',

  setup() {
    const router = useRouter();
    const routers = defluateRoutes[2].children;
    const routerState = ref(true);
    const size = ref(120);
    return {
      size,
      router,
      routers,
      routerState
    };
  }

};